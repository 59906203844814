<template>
  <div class="Sprint800">
    <div class="banner">
      <div class="textBox">
        <div class="title">Sprint 800</div>
        <div class="smallTitle">Desktop Thermal Printer</div>
      </div>
    </div>
    <div class="Features">
      <div class="left">
        <ul>
          <li>
            <i></i>
            <div>
              Electronic one-touch and emergency manual lid opening design
            </div>
          </li>
          <li>
            <i></i>
            <div>Super compact footprint (127×127×127mm)</div>
          </li>
          <li>
            <i></i>
            <div>
              <div>250mm/s high printing speed with low noise</div>
              <div>and overheat protection for longer lifespan</div>
            </div>
          </li>
        </ul>
      </div>
      <div class="right">
        <img src="../../../assets/Sprint800配图/PC/展示图2.png" alt="" />
      </div>
    </div>
    <ImgVideo :video="video"> </ImgVideo>
    <div class="product">
      <img src="../../../assets/Sprint800配图/PC/展示.jpg" alt="" />
    </div>
    <div class="Agility">
      <div class="w">
        <div class="titleBox">
          <div class="title">Sprint 800</div>
        </div>
        <div class="itemBox">
          <div class="one">
            <div class="itemA">
              <div class="title">Printing specifications</div>
              <div class="text">Printing Width: 72mm/48mm</div>
              <div class="text">Paper Roll Width: 58/80±0.5mm</div>
              <div class="text">Paper Roll Diameter: Outer diameter≤φ80mm</div>
              <div class="text">Printing Thickness: 0.056mm-0.09mm</div>
              <div class="text">
                Print Density: 576 dots per line (adjustable via commands)
              </div>
              <div class="text">Printing Speed: 250 mm/s</div>
              <div class="text">Print Head Life: 150 kilometers</div>
            </div>
            <div class="itemA">
              <div class="title">Font</div>
              <div class="text">
                Line spacing: 3.75mm (adjustable via commands)
              </div>
              <div class="text">Characters per line:</div>
              <div class="text">
                80 paper: Font A - 42 columns or 48 columns /
              </div>
              <div class="text">Font B - 56 columns or 64 columns /</div>
              <div class="text">
                Simplified / Traditional Chinese - 21 columns or 24 columns
              </div>
              <div class="text">Character size:</div>
              <div class="text">FontA: 1.5x3.0 (12x24 dots)</div>
              <div class="text">FontB: 1.1x2.1 (9x17 dots)</div>
              <div class="text">
                Simplified / Traditional Chinese: 3.0x3.0 (24x24 dots)
              </div>
            </div>
            <div class="itemA">
              <div class="title">Environment</div>
              <div class="text">Operating Environment:</div>
              <div class="text">Temperature: 0°C to 40°C</div>
              <div class="text">Humidity: 30% to 90% RH (non-condensing)</div>
              <div class="text">Storage Environment:</div>
              <div class="text">Temperature: -20°C to 55°C</div>
              <div class="text">Humidity: 20% to 93% RH (non-condensing)</div>
            </div>
            <div class="itemB">
              <div class="title">Interface Type</div>
              <div class="text">USB + COM (RS-232) + LAN (RJ-45)</div>
              <div class="text">Cash Drawer (Optional: Wi-Fi/Bluetooth)</div>
            </div>
            <div class="itemB">
              <div class="title">Emulation</div>
              <div class="text">Print Command: Compatible with ESC/POS</div>
              <div class="text">Driver: Windows/OPOS</div>
            </div>
            <div class="itemB">
              <div class="title">Printing method</div>
              <div class="text">Line thermal printing</div>
            </div>
            <div class="itemB">
              <div class="title">Buffering</div>
              <div class="text">NV FLASH Buffer: 256 KB</div>
              <div class="text">Input Buffer: 2048 Bytes</div>
              <div class="text">Cache: 8 MB</div>
            </div>
            <div class="itemB">
              <div class="title">Barcode Type</div>
              <div class="text">
                1D: UPCA/UPCE/JAN13(EAN13)/JAN8(EAN8)/CODE39/ITF/
              </div>
              <div class="text">CODABAR/CODE93/CODE128</div>
              <div class="text">2D: QR Code、PDF 417</div>
            </div>
            <div class="itemB">
              <div class="title">Support</div>
              <div class="text">OS: Windows/Linux/Android/iOS/Mac</div>
              <div class="text">SDK: Android/iOS/WeChat Mini Program</div>
            </div>
            <div class="itemB">
              <div class="title">Paper Feed Direction</div>
              <div class="text">Top Feeding / Front Feeding</div>
            </div>
            <div class="itemB">
              <div class="title">Auto Cutter</div>
              <div class="text">Automatic Cutter Blade: Half-Cut</div>
              <div class="text">Cutter Blade Life: 1.5 million times</div>
            </div>
            <div class="itemB">
              <div class="title">Power Supply</div>
              <div class="text">Power Input: DC 24V/2.5A</div>
              <div class="text">Cash Drawer Output: DC 24V/1A</div>
            </div>
          </div>
        </div>
        <div class="displayDrawing">
          <img
            class="img"
            src="../../../assets/Sprint800配图/PC/双色展示.png"
            alt=""
          />
        </div>
        <div class="downloads">
          <div class="box">
            <div class="itemA">
              <div class="title">Sprint 800</div>
              <div class="text">PDF(1.00MB)</div>
              <div class="icon">
                <a
                  href="https://www.compax.cc/download/manual/Sprint_800.pdf"
                  target="_blank"
                  ><i class="el-icon-bottom"></i
                ></a>
              </div>
            </div>
            <!-- <div class="itemB">
              <div class="title">Sprint 800 User Manual</div>
              <div class="text">PDF(1.08MB)</div>
              <div class="icon">
                <a
                  href="https://www.compax.cc/download/manual/Agility_A-90_User_Manual.pdf"
                  target="_blank"
                  ><i class="el-icon-bottom"></i
                ></a>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImgVideo from '@/components/imgVideo'
import video from '../../../assets/Sprint800配图/Compax_Sprint_800_Video.mp4'
export default {
  components: {
    ImgVideo
  },
  data() {
    return {
      video
    }
  }
}
</script>

<style lang="less">
.Sprint800 {
  .banner {
    position: relative;
    height: 1000px;
    background: url('../../../assets/Sprint800配图/PC/网站头图.jpg') no-repeat
      center;
    background-size: cover;
    .textBox {
      position: absolute;
      top: 120px;
      left: 506px;
      .title {
        color: rgba(0, 92, 255, 1);
        font-size: 72px;
        font-weight: 600;
        line-height: 95.47px;
      }
      .smallTitle {
        margin: 16px 0px 60px 0px;
        color: #fff;
        font-size: 36px;
        line-height: 52.13px;
      }
    }
  }
  .Features {
    position: relative;
    height: 1060px;
    background: #000;
    .left {
      position: absolute;
      top: 222px;
      left: 329px;
      ul {
        li {
          text-align: justify;
          display: flex;
          // width: 674px;
          margin-bottom: 73px;
          line-height: 34.75px;
          color: #fff;
          font-size: 24px;
          i {
            margin: 5px 8px 0px 0px;
            width: 0;
            height: 0;
            font-size: 0;
            line-height: 0;
            border-top: 13px solid transparent;
            border-left: 26px solid rgba(0, 92, 255, 1);
            border-bottom: 13px solid transparent;
          }
        }
      }
    }
    .right {
      position: absolute;
      top: 361px;
      left: 840px;
      width: 853px;
      height: 593px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .product {
    background: #000;
    img {
      width: 100%;
      height: 1164px;
    }
  }
  .displayDrawing {
    display: flex;
    justify-content: center;
    img {
      width: 1294px;
      height: 489px;
    }
  }
  .Agility {
    background: rgba(0, 0, 0, 1);
    padding: 103px 11px 0px;
    .titleBox {
      display: flex;
      position: relative;
      font-weight: 500;
      line-height: 25px;
      color: rgba(0, 92, 255, 1);
      font-size: 30px;
      padding-bottom: 23px;
      border-bottom: 4px solid rgba(0, 92, 255, 0.5);
      &::after {
        position: absolute;
        left: 0px;
        bottom: -4px;
        content: '';
        width: 165px;
        height: 0px;
        border-bottom: 4px solid rgba(0, 92, 255, 0.5);
        background: rgba(0, 92, 255, 1);
      }
      .title {
        margin-right: 73px;
      }
    }
    .itemBox {
      margin-top: 21px;
      padding: 0px 10px;
      .title {
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        color: rgba(255, 255, 255, 1);
        margin-bottom: 5px;
      }
      .text {
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        color: rgba(128, 128, 128, 1);
      }
      .itemA {
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 382px;
        height: 270px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(27, 32, 48, 1);
      }
      .itemB {
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 383px;
        height: 140px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(27, 32, 48, 1);
      }
      .one {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }
    .downloads {
      .box {
        padding: 0px 10px;
        display: flex;
        .title {
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          color: rgba(255, 255, 255, 1);
          margin-bottom: 5px;
        }
        .text {
          font-size: 12px;
          font-weight: 500;
          line-height: 20px;
          color: rgba(128, 128, 128, 1);
        }
        .itemA {
          margin-right: 30px;
          padding: 19px 0px 0px 26px;
          width: 220px;
          height: 140px;
          opacity: 1;
          border-radius: 20px;
          background: rgba(27, 32, 48, 1);
          .icon {
            font-size: 24px;
            border-radius: 50%;
            color: rgba(0, 92, 255, 1);
            margin-top: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36.55px;
            border: 1px solid rgba(0, 92, 255, 1);
          }
        }
        .itemB {
          margin-right: 30px;
          padding: 19px 0px 0px 26px;
          width: 251px;
          height: 140px;
          opacity: 1;
          border-radius: 20px;
          background: rgba(27, 32, 48, 1);
          .icon {
            font-size: 24px;
            border-radius: 50%;
            color: rgba(0, 92, 255, 1);
            margin-top: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36.55px;
            border: 1px solid rgba(0, 92, 255, 1);
          }
        }
      }
    }
  }
}
</style>
